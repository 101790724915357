.manageForms form {
    position: relative;
    max-width: 100%;
}

.ui.form .field {
    clear: both;
    margin: 0 0 1em;
}

.ui.form .field>label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0, 0, 0, .87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
}

.ui.form .required.field>label:after,
.ui.form .required.fields.grouped>label:after,
.ui.form .required.fields:not(.grouped)>.field>label:after {
    display: inline-block;
    vertical-align: top;
}

.ui.form .required.field>.checkbox:after,
.ui.form .required.field>label:after,
.ui.form .required.fields.grouped>label:after,
.ui.form .required.fields:not(.grouped)>.field>.checkbox:after,
.ui.form .required.fields:not(.grouped)>.field>label:after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: #db2828;
}

.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
    width: 100%;
}

.ui.input {
    font-size: 1em
}

.ui.input {
    position: relative;
    font-weight: 400;
    font-style: normal;
    display: inline-flex;
    color: rgba(0, 0, 0, .87);
    width: 99%;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    width: auto;
}

.ui.disabled.input>input,
.ui.input:not(.disabled) input[disabled] {
    pointer-events: none;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
    opacity: .45;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
    pointer-events: none;
    opacity: .45;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease, border-color .1s ease;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url],
.ui.form textarea {
    width: 100%;
    vertical-align: top;
}

.ui.input>input {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: 0.28571429rem;
    transition: box-shadow .1s ease, border-color .1s ease;
    box-shadow: none;
}

input[type=email],
input[type=password],
input[type=search],
input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ui.form .field>.selection.dropdown {
    width: 100%;
}

.ui.selection.dropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    transform: rotateZ(0);
    min-width: 14em;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
    color: rgba(0, 0, 0, .87);
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 0.28571429rem;
    transition: box-shadow .1s ease, width .1s ease;
}

select.ui.dropdown {
    height: 38px;
    padding: 0.5em;
    border: 1px solid rgba(34, 36, 38, .15);
    visibility: visible;
}

.ui.form select {
    display: block;
    height: auto;
    width: 100%;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    padding: 0.62em 1em;
    color: rgba(0, 0, 0, .87);
    transition: color .1s ease, border-color .1s ease;
}

.ui.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: 0;
    text-align: left;
    transition: box-shadow .1s ease, width .1s ease;
    -webkit-tap-highlight-color: transparent;
}

button,
select {
    text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

.field textarea {
    height: 200px;
    width: 95% !important;
}

.readOnlyInput input {
    cursor: not-allowed;
    background-color: #e5e5e5 !important;
}

.readOnlyInput select {
    cursor: not-allowed;
    background-color: #e5e5e5 !important;
}

.readOnlyInput textarea {
    cursor: not-allowed;
    background-color: #e5e5e5 !important;
}