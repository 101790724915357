  .brd {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
  }
  
  #info {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    color: #2d3667;
    font-size: 16px;
    text-align: center;
    padding: 14px;
    background-color: #f3f9f9;
  }
  
  #app-cover {
    position: relative;
    /* top: 45px; */
    /* right: 0; */
    /* left: 0; */
    width: 150px;
    margin: 7px 0px 0px 0px;
    float: right;
    z-index: 1;
  }
  
  #select-button {
    position: relative;
    display: flex;
    height: 16px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #02113a;
  }
  
  #options-view-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  #selected-value {
    font-size: 13px;
    /* line-height: 1; */
    /* margin-right: 26px; */
    /* position: relative; */
    /* top: -25px; */
    text-align: center;
  }
  
  
  .option,
  .label {
    color: #2d3667;
    font-size: 14px;
  }
  
  #chevrons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 12px;
    padding: 9px 10px;
  }
  
  #chevrons i {
    display: block;
    height: 50%;
    color: #d1dede;
    font-size: 12px;
    text-align: right;
  }
  
  #options-view-button:checked + #select-button #chevrons i {
    color: #2d3667;
  }
  
  
  #options {
    position: absolute;
    top: 42px;
    right: 0;
    width: max-content;
    left: 0;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
}
  
  .option {
    position: relative;
    line-height: 1;
    transition: 0.3s ease all;
    z-index: 2;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 14px;
    justify-content: flex-start;
    padding: 0px 10px;
  }
  
  .option i {
    padding: 12px 0;
    display: block;
  }
  
  #options-view-button:checked ~ #options .option i {
    display: block;
    padding: 12px 0;
  }
  
  .label {
    display: block;
    padding: 12px 14px;
  }
  
  #options-view-button:checked ~ #options .label {
    display: block;
    padding: 12px 14px;
  }
  
  .s-c {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }
  
  .s-c.top {
    top: 0;
  }
  
  .s-c.bottom {
    bottom: 0;
  }
  
  input[type="radio"] {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .s-c:hover ~ i {
    color: #fff;
    opacity: 0;
  }
  
  .s-c:hover {
    height: 100%;
    z-index: 1;
  }
  
  .s-c.bottom:hover + i {
    bottom: -25px;
    animation: moveup 0.3s ease 0.1s forwards;
  }
  
  .s-c.top:hover ~ i {
    top: -25px;
    animation: movedown 0.3s ease 0.1s forwards;
  }
  
  @keyframes moveup {
    0% {
      bottom: -25px;
      opacity: 0;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
  
  @keyframes movedown {
    0% {
      top: -25px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  
  .label {
    transition: 0.3s ease all;
  }
  
  .opt-val {
    position: absolute;
    left: 14px;
    width: 217px;
    height: 21px;
    opacity: 0;
    background-color: #fff;
    transform: scale(0);
  }
  
  .option input[type="radio"]:checked ~ .opt-val {
    opacity: 1;
    transform: scale(1);
  }
  
  .option input[type="radio"]:checked ~ i {
    top: 0;
    bottom: auto;
    opacity: 1;
    animation: unset;
  }
  
  .option input[type="radio"]:checked ~ i,
  .option input[type="radio"]:checked ~ .label {
    color: #fff;
  }
  
  .option input[type="radio"]:checked ~ .label:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  
  .option input[type="radio"]:checked ~ .label:before {
    background-color: #2d3667;
    color: white;
  }
  
  #option-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 40px;
    transition: 0.3s ease all;
    z-index: 1;
    display: block;
  }
  
  .option:hover {
    background-color: #02113a;
    border-radius: 4px 4px 0 0;
    color: white;
  }
  
  .option:hover .label {
    color: #fff;
  }

  .hide {
    display: none;
  }