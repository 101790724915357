.nd_loader {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nd_loaderText {
  font-size: 11px;
  font-family: montserrat;
  font-weight: 800;
  color: #bdc1ca;
  /* width: 119px; */
  position: relative;
  top: 50px;
}

.nd_loadingContent {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  margin: auto;
  height: 100%;
  width: inherit;
}

.load {
  --fill: #7c7c7c;
  width: 18px;
  height: 28px;
  transform-origin: 45% 50%;
  transform: rotate(-90deg);
  animation: turn 2s linear infinite;
  svg {
      display: block;
      fill: var(--fill);
  }
}

@keyframes turn {
  25%,
  50% {
      transform: rotate(45deg);
  }
  75%,
  100% {
      transform: rotate(270deg);
  }
}