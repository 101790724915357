@-webkit-keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
}

.loaderVector {
  margin: -18px 0 0 -18px;
  border: 3.6px solid #bdc1ca;
  box-sizing: border-box;
  overflow: hidden;
  width: 36px;
  height: 36px;
  animation: loader-spin 2s linear infinite reverse;
  filter: url(#goo);
  box-shadow: 0 0 0 1px #bdc1ca inset;
}

.loaderVector:before {
  content: "";
  position: absolute;
  -webkit-animation: loader-spin 2s cubic-bezier(0.59, 0.25, 0.4, 0.69) infinite;
  animation: loader-spin 2s cubic-bezier(0.59, 0.25, 0.4, 0.69) infinite;
  background: rgb(150 160 179 / 63%);
  transform-origin: top center;
  border-radius: 50%;
  width: 150%;
  height: 150%;
  top: 50%;
  left: -12.5%;
}

.loaderText {
  font-size: 11px;
  font-family: montserrat;
  font-weight: 800;
  color: #bdc1ca;
  /* width: 119px; */
  position: relative;
  left: -10px;
  top: 20px;
}

.loadingContent {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  margin: auto;
  height: 100%;
  width: inherit;
  left: 15px;
}