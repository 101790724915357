.mapboxgl-ctrl-geocoder input[type=text] {
  padding: 5px 43px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 0px solid #707070 !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 0px #ababab !important;
}

.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  fill: #A8AAAA !important;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 11px !important;
  left: 15px;
  width: 18px;
  height: 18px;
}

.mapboxgl-ctrl-geocoder {
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: relative;
  background-color: #2e3433;
  border-radius: 15px !important;
  width: 100%;
  min-width: 240px;
  z-index: 1;
  border-radius: 4px;
  transition: width .25s, min-width .25s;
}

.configInputMap .locationSearch {
  position: relative;
  width: 90%;
  margin: 0 auto;
  top: 30px;
}

.configInputMap .locationSearch input {
  background: #2E3433 !important;
  color: white !important;
}

.configInputMap .locationSearch input::placeholder {
  color: #A8AAAA;
}

.mapboxgl-canvas {
  height: 110% !important;
  width: 100% !important
}

.mapboxgl-ctrl-top-left {
  top: 12%;
  left: 1%
}

.map-overlay h2 {
  margin: 0 0 10px;
  padding: 10px
}

.mapboxgl-ctrl-geocoder {
  margin: 20px !important;
  width: 100% !important;
  font-size: 15px !important;
  line-height: 20px !important;
  max-width: -webkit-fill-available !important;
  border-radius: 9px !important
}


.configInputMap .mapContainer {
  overflow: hidden;
  width: inherit;
  position: absolute;
  height: inherit;
  border-radius: 15px;
}

.mapboxgl-canvas {
  height: 110% !important;
  width: 100% !important
}

.coordinates {
  background: rgba(0, 0, 0, .5);
  color: #fff;
  position: absolute;
  bottom: 40px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none
}

.mapboxgl-ctrl-top-left {
  top: 12%;
  left: 1%
}

.map-overlay h2 {
  margin: 0 0 10px;
  padding: 10px
}

.mapboxgl-ctrl-geocoder {
  margin: 20px !important;
  width: 100% !important;
  font-size: 15px !important;
  line-height: 20px !important;
  max-width: -webkit-fill-available !important;
  border-radius: 9px !important
}

.mapboxgl-ctrl-geocoder input[type=text] {
  padding: 5px 43px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 0px solid #707070 !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 0px #ababab !important;
}

.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  fill: #A8AAAA !important;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 11px !important;
  left: 15px;
  width: 18px;
  height: 18px;
}

.mapboxgl-ctrl-geocoder {
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: relative;
  background-color: #2e3433;
  border-radius: 15px !important;
  width: 100%;
  min-width: 240px;
  z-index: 1;
  border-radius: 4px;
  transition: width .25s, min-width .25s;
}

.configInputMap .parentMapContainer {
  height: 35vh;
  width: inherit;
  position: relative;
}

.configInputMap .parentMapContainer h1 {
  font-size: 13px;
  color: #999393;
  font-weight: 700;
  font-family: 'montserrat';
}

.latlonBar {
  position: absolute;
  width: inherit;
  z-index: 10;
  margin: auto;
  text-align: center;
  font-family: montserrat;
  font-size: 13px;
  margin-top: 20px;
  color: #000000;
  font-weight: 800;
  background: #f5f4f2;
  padding: 12px 0px;
}

.locationSearch {
  position: absolute;
  width: inherit;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

.configInputMap {
  width: 100%;
}