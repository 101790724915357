html {
    scrollbar-width: 10px;
}

::-webkit-scrollbar {
    width: 0px;
    height: 5px;
}

/* Color of the scrollbar handle */
::-webkit-scrollbar-thumb {
    background: #b4b4b46c;
    border-radius: 10px;
}

/* Color on the top and bottom of the scrollbar handle */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.publicLandingContainer {
    height: 100%;
    width: 100%;
}

.publicParentContainer {
    background-color: #02113b;
    height: 100%;
    width: 100%;
    position: fixed;
}

.landingContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.landingHeader {
    position: fixed;
    width: 100%;
    margin: 20px 50px;
    font-size: 13px;
    height: 70px;
    z-index: 1000;
}

#landingVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 110%;
    opacity: 0.2;
}

.publicLogo {
    font-family: good-times, sans-serif;
    color: #cbbb00 !important;
    float: left;
    display: block;
    cursor: pointer;
}

.publicLogo h1 {
    font-weight: 600 !important;
}

.publicNavMenu {
    font-family: montserrat, sans-serif;
    display: inline-flex;
    color: white;
    float: right;
    font-size: 11px;
    position: relative;
    right: 125px;
}

.publicNavMenuOnHighlight {
    color: #404040 !important;
}

.publicNavMenu h1 {
    color: white;
    margin: 20px 30px 0px 30px;
    font-weight: 600;
    cursor: pointer;
    z-index: 1000;
}

.publicNavMenu h1:hover {
    text-decoration: underline;
}

.publicSloganText {
    font-family: good-times, sans-serif;
    color: #ceb616ad;
    font-size: 4vw;
}

.publicSloganText h1 {
    font-weight: 200;
    position: relative;
    margin: 10px 0px;
}

.slideView {
    position: fixed;
    height: 100%;
    top: 0%;
    right: 0px;
    width: 550px;
    float: right;
    z-index: 1000;
    opacity: 0;
    transform: translateX(100%);
    background-color: #ffffff;
    overflow-y: scroll;
    display: flex;
    align-items: center;
}

.slideViewInActive {
    animation: slideOut 0.5s ease forwards;
}

.slideViewActive {
    animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateX(0%);
    }

    to {
        opacity: 0;
        transform: translateX(100%);
    }
}

.slideForm {
    position: relative;
    left: 20%;
    color: #414141;
    width: 400px;
    height: 90%;
    overflow: scroll;
}

.slideForm h1 {
    font-family: good-times, sans-serif;
    font-weight: 600;
    margin: 8px 0px;
}

.slideForm p {
    color: #343434;
    font-size: 18px;
    font-weight: 100;
}

.signupForm {
    display: none;
}

.signupForm h1 {
    font-size: 25px;
}

.loginForm {
    display: none;
    height: auto !important;
}

.loginForm h1 {
    font-size: 30px;
}

.formInputs {
    margin-bottom: 10px;
    width: 98%;

    .idTagAutocompleteList {
        list-style-type: none;
        margin: 0;
        padding: 5px 0px;
        width: 100%;
        max-height: 150px;
        overflow-y: auto;
    }

    .idTagAutocompleteList li {
        padding: 5px 12px;
        cursor: pointer;
    }
}

.formInputs label {
    display: block;
    font-family: "montserrat";
    font-size: 12px;
    color: #605a5a;
    margin: 7px 0px;
}

.formInputs input {
    display: block;
    padding: 13px;
    width: 305px;
    border-radius: 8px;
    border: 1px solid #00081a;
    padding: 12px;
}

.formInputs select {
    display: block;
    padding: 13px;
    width: 334px;
    border-radius: 8px;
    border: 1px solid #00081a;
    padding: 12px;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.formButtons {
    margin-top: 30px;
}

.formButtons div {
    width: 88%;
    display: inline-flex;
    justify-content: center;
}

.formButtons div p {
    font-size: 19px;
    margin: 10px 0px;
    text-align: center;
}

.formButtons div span {
    font-size: 18px;
    margin: 10px 10px;
    cursor: pointer;
    /* text-align: center; */
    color: #7f91be;
    text-decoration: underline;
}

.primaryButton {
    height: 45px;
    width: 210px;
    margin: 10px 15px 10px 0px;
    background: #00081a;
    border: 3px solid #a7b0cc;
    border-radius: 5px;
    color: #ffffff;
    font-family: "montserrat";
    font-size: 15px;
    cursor: pointer;
}

.secondaryButton {
    height: 45px;
    width: 110px;
    margin: 10px 15px 10px 0px;
    background: #5f6984;
    border: 3px solid #9a9a9a;
    border-radius: 5px;
    color: #ffffff;
    font-family: "montserrat";
    font-size: 15px;
    cursor: pointer;
}

.accountLogo {
    display: block;
    text-align: center;
    cursor: pointer;
}

.accountLogo h1 span {
    color: #cbbb00 !important;
    font-family: good-times, sans-serif !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    display: none;
}

.accountLogo h1 i {
    color: #ffee20 !important;
    font-size: 40px !important;
    font-weight: 600 !important;
}

.accountNavBar:hover {
    width: 250px !important;
}

/* .accountNavBar:hover >div> h1> span {
	display: block !important;
}

.accountNavBar:hover >div> h1> i {
	display: none !important;
} */

.accountNavBar {
    transition: all 0.5s;
    position: fixed;
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    width: 100px;
    background-color: #02113b;
    top: 0;
    left: 0;
    border-right: 4px solid #cbbb00;
    align-items: center;
    z-index: 1010;
    overflow: scroll;
}

.childNav {
    background: #152a63;
    height: 0px;
    width: 220px;
    left: 0px;
    opacity: 0;
    padding: 15px;
    display: none;
    position: relative;
}

.childNav h1 {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0px;
    font-family: "montserrat";
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.childNav h1:hover {
    color: #7b93d4;
}

.childNav i {
    font-size: 12px !important;
    margin-right: 10px;
}

.accountNavBar > div {
    text-align: center;
    font-size: 9px;
    color: white;
}

.accountNavBar > div > h1 {
    font-family: "montserrat";
    font-weight: 700;
    padding: 10px 0px;
    cursor: pointer;
}

.accountNavBar > div > h1 > span {
    display: none;
}

.accountNavBar > div > h1:hover {
    color: #7b93d4;
}

.accountNavBar > div:first-of-type {
    padding-top: 50px;
}

.accountNavBar > div:last-of-type {
    padding-bottom: 50px;
}

.accountNavBar i {
    display: block;
    font-size: 20px;
}

.accountContent {
    position: relative;
    padding: 40px 0px 40px 0px;
    height: 100%;
    margin: auto;
    width: 80%;
    margin-left: 200px;
    margin-right: 100px;
}

.accountParentContainer {
    display: flex;
    height: 100%;
}

.accountContainer {
    padding: 30px 0px;
    width: 100%;
    height: 100%;
}

.analyticsCharts {
    width: 100%;
    height: 100%;
}

.analyticsCharts .analytic {
    background: #ffffff;
    border-radius: 20px;
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    box-shadow: -3px 3px 8px #b3b3b3;
}

.accountAlert {
    height: 100px;
    width: 100%;
    background: #ffa8a8;
    border-radius: 10px;
    box-shadow: 2px 4px 10px #b3b3b3;
    display: flex;
    align-items: center;
}

.accountAlert p {
    font-family: "montserrat";
    font-weight: 600;
    color: #3e3e3e;
    padding: 0px 25px;
    font-size: 14px;
}

.accountAlert i {
    position: absolute;
    float: right;
    right: 15px;
    top: 55px;
    color: #3e3e3e;
    font-size: 15px;
    cursor: pointer;
}

.chartContainer {
    width: 100%;
    position: absolute;
}

.dashboardChartContainer {
    width: 100%;
    height: inherit;
    position: relative;
}

.chart {
    padding: 10px;
    height: 100%;
}

.sectionHeader {
    display: flex;
    flex-direction: row;
}

.sectionHeader h1 {
    position: relative;
    /* top: 10px; */
    left: 0;
    font-family: "montserrat";
    font-weight: 600;
    font-size: 27px;
    margin: 0px;
}

.deviceContentContainer .sectionHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.deviceContentContainer .sectionHeader input {
    position: relative;
    width: 76%;
    height: 40px;
    border: 2px solid #b9aaaa;
    border-radius: 4px;
    padding: 10px;
    top: 0px;
}

.deviceContentContainer .sectionHeader > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.deviceContentContainer .sectionHeader button {
    position: relative;
    /* top: -25px; */
    /* right: 81px; */
    float: right;
    padding: 8px;
    color: white;
    background: #02113b;
    border-radius: 5px;
    width: 150px;
    border: 2px solid #02113b;
    font-family: "montserrat";
    cursor: pointer;
    font-size: 13px;
    height: 40px;
}

/* 
.sectionHeader select {
	position: relative;
    top: -30px;
    left: -9px;
    font-family: "montserrat";
    font-weight: 600;
    font-size: 27px;
    margin: 0px;
    border: 0px;
    width: 35%;
} */

.sectionHeader button {
    position: relative;
    /* top: -25px; */
    /* right: 81px; */
    float: right;
    padding: 8px;
    color: white;
    background: #02113b;
    border-radius: 5px;
    width: 150px;
    border: 2px solid #02113b;
    font-family: "montserrat";
    cursor: pointer;
}

.sectionHeader button:hover {
    background: #152a64;
}

.sectionHeader input {
    position: relative;
    width: 100%;
    height: 40px;
    border: 2px solid #b9aaaa;
    border-radius: 4px;
    padding: 10px;
    top: 10px;
}

.sectionContent {
    margin: 15px 5px;
}

.listTable {
    width: 100%;
    height: auto;
    max-height: 69vh;
}

.listTableHeader {
    display: inline-flex;
    width: 100%;
    font-family: "montserrat";
    font-size: 10px;
    border-bottom: 2px solid #afafaf;
    font-weight: 600;
}

.listTableHeader i {
    width: 10%;
}

.listTableHeader p:nth-of-type(1) {
    width: 35%;
    color: "#315bb9";
}

.listTableHeader p:nth-of-type(2) {
    width: 35%;
}

.listTableHeader p:nth-of-type(3) {
    width: 30%;
}

.listTableHeader p:nth-of-type(4) {
    width: 30%;
}

.listTableHeader p:nth-of-type(5) {
    width: 35%;
}

.listTableHeader p:nth-of-type(6) {
    width: 35%;
}

.listTableRows {
    max-height: inherit;
    overflow: scroll;
}

.listTableRows div:nth-child(even) {
    background: rgb(249 249 249);
}

.listRow {
    display: flex;
    font-family: "montserrat";
    font-size: 13px;
    align-items: center;
    cursor: pointer;
}

.listRow:hover {
    background: #f1f4ff !important;
}

.Closed {
    color: #999999 !important;
}

.Inactive {
    color: #e1411e !important;
}

.Active {
    color: rgb(27, 185, 27) !important;
}

.listRow i {
    text-align: center;
    width: 10%;
}

.listRow p:nth-of-type(1) {
    width: 35%;
}

.listRow p:nth-of-type(2) {
    width: 35%;
}

.listRow p:nth-of-type(3) {
    width: 30%;
}

.listRow p:nth-of-type(4) {
    width: 30%;
}

.listRow p:nth-of-type(5) {
    width: 35%;
}

.listRow p:nth-of-type(6) {
    width: 35%;
}

.listRow > .viewButton {
    width: 5%;
}

.inputError {
    color: black !important;
    border: 1.5px solid #eb3f3f !important;
}

// select.inputError {
// 	color: #eb3f3f  !important;
// 	border: 1.5px solid #eb3f3f !important;
// }

// input.inputError::placeholder, textarea.inputError::placeholder {
// 	color: #eb3f3f !important;
// }

// select.inputError > *{
// 	color:blue !important;
// }

.createModal {
    width: 500px;
    display: block;
    margin: auto;
    background: white;
    border: 2px solid black;
    border-radius: 10px;
    padding: 0px 50px 20px 50px;
    overflow-y: scroll;
}

.createModal h1 {
    margin: 0px;
}

.createModal i {
    position: relative;
    height: 0px;
    top: 30px;
    left: 470px;
    font-size: 24px;
    cursor: pointer;
}

.createForm {
    padding: 20px 0px;
    max-height: 60vh;
    overflow-y: scroll;
}

.createForm h1 {
    margin: 0px;
    font-size: 30px;
    margin-bottom: 25px;
}

.createModal select {
    width: 101%;
    font-size: 13px;
    color: black;
}

.createModal textarea {
    width: 94%;
    padding: 15px;
    font-size: 13px;
    height: 80px;
}

.createModal input {
    width: 96%;
    font-size: 13px;
}

.createModal input::placeholder {
    color: rgb(169, 169, 169);
}

.modalBackground {
    position: fixed;
    top: 0;
    left: 80px;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 0.6);
    display: none;
    z-index: 100;
}

.modalChildInputs {
    display: none;
    background-color: #e9e9e9;
    padding: 18px;
    margin-top: -7px;
    margin-bottom: 12px;
    width: 92%;
    opacity: 0;
    border-radius: 0px 0px 12px 12px;
}

.modalChildInputs .formInputs {
    display: none;
}

.uploadInput {
    position: relative;
}

.uploadBox {
    width: 100% !important;
    height: 225px !important;
    border: 3px dashed #02113b;
}

.uploadBox i {
    font-size: 40px;
    position: relative;
    top: 37%;
    right: 14%;
}

.uploadBox p {
    position: relative;
    top: 17%;
    left: 3%;
}

.uploadToolText {
    width: 103%;
    padding: 5px;
    background: #02113b;
    border: 0px solid;
    border-radius: 5px;
    margin: 10px -3px;
    color: white;
}

.playgroundContent {
    float: left;
    width: 450px;
    height: 100%;
    margin: 5px 0px;
    padding: 0px 30px;
    border-right: 3px dashed #dedede;
}

.playgroundContent button {
    position: relative;
    width: 103%;
    margin: 15px -3px;
    padding: 10px;
    background: #02113b;
    color: white;
    border-radius: 10px;
    border: 1px solid white;
}

.playgroundChart {
    width: 100%;
    height: 80vh;
}

.deviceDashboardContainer {
    width: 100%;
    margin-bottom: 40px;
}

.playgroundGuidelines p {
    font-weight: 600;
    text-decoration: underline;
}

.playgroundGuidelines ol {
    list-style: circle;
    padding: 0px 20px;
}

.playgroundGuidelines li {
    margin: 10px 0px;
}

.playgroundEg {
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;
    border: 3px solid #cacaca;
}

.playgroundEg:hover {
    background: #dedede;
}

.playgroundEg i {
    font-size: 25px;
    color: green;
    padding: 0px 15px;
}

.onBoardingContainer {
    text-align: center;
    height: 100%;
    margin-top: 5%;
}

.onBoardingText {
    margin: 15px 0px;
}

.onBoardingText h1 {
    font-weight: 500;
    font-family: "good-times";
    font-size: 45px;
    color: #727272;
}

.onBoardingText p {
    font-family: "montserrat";
    font-size: 16px;
    width: 700px;
    margin: auto;
    font-weight: 100;
    color: #727272;
    line-height: 30px;
}

.onBoardingForm {
    font-family: "montserrat";
    width: 600px;
    margin: auto;
    display: grid;
    text-align: left;
    grid-template-columns: 50% 50%;
}

.onBoardingForm h1 {
    font-size: 20px;
    margin-bottom: 25px;
    grid-column: 1 / span 2;
}

.onBoardingForm > div {
    width: 100%;
    display: grid;
    grid-column: 1 / span 2;
}

.onBoardingForm > div input,
select {
    width: 97%;
    margin: 15px 0px;
    border-radius: 8px;
    border: 1px solid gray;
    padding: 8px;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

.onBoardingNext {
    background: #f3f3f3;
    color: #00081a;
    width: 600px;
    margin: auto;
    text-align: left;
    height: 160px;
    border-radius: 10px;
    margin-top: 15px;
}

.onBoardingNext h1 {
    font-size: 20px;
    padding: 20px 20px 0px 20px;
    margin: 0px 10px;
}

.onBoardingNextStep {
    color: #00081a;
    display: flex;
    margin: 15px 30px;
    cursor: pointer;
}

.onBoardingNextStep div {
    display: flex;
    height: 25px;
    width: 25px;
    border-radius: 100px;
    background: white;
    border: 1px solid gray;
}

.onBoardingNextStep p {
    margin: 0px 10px;
    font-size: 16px;
}

.onBoardingContainer > button {
    background: #00081a;
    width: 600px;
    margin: auto;
    height: 35px;
    color: white;
    border: 0px;
    border-radius: 10px;
    margin-top: 21px;
}

.landingContainer {
    width: 100%;
    padding-top: 15px;
}

.createButtons {
    float: left;
}

.createButtons button {
    height: 35px;
    border: none;
}

.createButtons .primaryButton {
    width: 180px !important;
}

.createButtons .secondaryButton {
    width: 100px !important;
}

.deviceContainer {
    width: 75vw;
    max-height: 90vh;
}

.deviceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deviceHeader h1 {
    color: #02113b;
    font-family: "montserrat";
    font-weight: 200;
    margin: 25px 0px 0px 0px;
}

.deviceHeader button {
    height: 35px;
    width: 150px;
    background: #071534;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 13px;
    margin: 5px;
}

.deviceHeader .editButtons {
    opacity: 0;
    display: none;
}

.deviceHeader .primaryButton {
    height: 35px;
    width: 150px;
    background: #071534;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 13px;
    margin: 5px;
}

.deviceHeader .editButtons .primaryButton {
    height: 35px;
    width: 150px;
    background: #4e9664;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 13px;
    margin: 5px;
}

.deviceHeader .editButtons .secondaryButton {
    height: 35px;
    width: 150px;
    background: #983838;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 13px;
    margin: 5px;
}

.disabledInput {
    background: #e8e8e8 !important;
    cursor: not-allowed;
}

.permanentlyDisabledInput {
    background: #e8e8e8 !important;
    cursor: not-allowed;
}

.deviceMenu {
    display: flex;
    justify-content: space-between;
    margin: 25px 0px;
    border-bottom: 3px solid #bdbdbd;
}

.deviceMenu > div {
    cursor: pointer;
}

.deviceMenu h1 {
    font-family: "montserrat";
    font-size: 15px;
    font-weight: 300;
    color: #989898;
    margin: 0px;
}

@media only screen and (max-width: 1150px) {
    .deviceMenu h1 {
        font-family: "montserrat";
        font-size: 11px;
        font-weight: 300;
        color: #989898;
        margin: 0px;
    }
}

.deviceMenu h1:hover {
    color: #2c4f9c !important;
    font-weight: 700 !important;
}

.deviceMenu hr {
    opacity: 0;
    position: relative;
    top: 4px;
    height: 5px;
    margin: 0px 0px;
    border: none;
    background: #2c4f9c;
    padding: 0px;
}

.activeMenuItem_Hover hr {
    opacity: 1;
}

.activeMenuItem_Hover h1 {
    color: #2c4f9c !important;
    font-weight: 700 !important;
}

.activeMenuItem_Click hr {
    opacity: 1;
}

.activeMenuItem_Click h1 {
    color: #2c4f9c !important;
    font-weight: 700 !important;
}

.activeNavItem {
    color: #7b93d4;
}

.configPrimaryDetails {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto auto auto;
    width: 100%;
    gap: 10px;
}

.configPrimaryDetails > div {
    display: flex;
    flex-direction: column;
}

.configPrimaryDetails > div > label {
    font-family: "montserrat";
    font-size: 14px;
    margin: 5px 0px;
}

.configPrimaryDetails > div > input {
    width: 95%;
    border-radius: 8px;
    border: 1px solid gray;
    padding: 7px;
    background: #f6f8ff;
}

.configPrimaryDetails > div > textarea {
    width: 95%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid gray;
    padding: 7px;
    background: #f6f8ff;
}

.configSecondaryDetails {
    display: grid;
    gap: 20px;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    margin-top: 30px;
}

.configSecondaryDetails > div {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 30px 25px 50px 25px;
    display: grid;
    gap: 5px;
}

.configSecondaryDetails > div > h1 {
    font-family: "montserrat";
    font-size: 18px;
    margin: 0px;
    margin-bottom: 15px;
}

.configSecondaryDetails > div > div {
    display: flex;
    flex-direction: column;
}

.configSecondaryDetails > div > div > label {
    font-family: "montserrat";
    font-size: 14px;
    margin: 5px 0px;
}

.configSecondaryDetails > div > div > input,
textarea {
    width: 90%;
    border-radius: 8px;
    border: 1px solid gray;
    padding: 7px;
    background: #ffffff;
}

.deviceFormulaContainer {
    display: grid;
    grid-template-columns: 72% 25%;
    grid-template-rows: auto;
    grid-column-gap: 3%;
}

.formulaPrimaryDetails {
    display: grid;
    width: 94%;
    grid-gap: 10px;
    gap: 10px;
    height: fit-content;
    border-radius: 10px;
    background: #f8f8f8;
    padding: 25px;
}

.formulaPrimaryDetails > span {
    text-align: center;
    font-size: 45px;
}

.formulaPrimaryDetails > div > label {
    font-family: "montserrat";
    font-size: 14px;
    margin: 5px 0px;
}

.formulaPrimaryDetails > div > input {
    width: 95%;
    border-radius: 8px;
    border: 1px solid gray;
    padding: 7px;
    background: #f6f8ff;
    margin: 10px 0px;
}

.formulaPrimaryDetails button {
    border-radius: 7px;
    height: 30px;
    border: 1px solid #02113b;
    font-weight: 800;
    font-family: "montserrat";
    background: #dedede;
    color: black;
}

.formulaSecondaryDetails {
    display: grid;
    grid-template-rows: 40% 60%;
    grid-gap: 10px;
    gap: 10px;
    background: #f8f8f8;
    border-radius: 10px;
    padding: 30px 25px 50px 25px;
}

.formulaSecondaryDetails > div > input,
textarea,
select {
    width: 95%;
    border-radius: 8px;
    border: 1px solid gray;
    padding: 7px;
    background: #ffffff;
    height: 100%;
    margin: 5px 0px;
}

.formulaSecondaryDetails > div > label {
    font-family: "montserrat";
    font-size: 14px;
    margin: 5px 0px;
}

.formulaVariableDefinition {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.formulaVariableDefinition > p {
    font-size: 0.8vw;
    font-family: "montserrat";
}

.formulaVariableDefinition > input {
    height: 13px;
    width: 20%;
    margin: 0px 5px;
    padding: 5px;
    text-align: center;
    background: #f6f8ff;
    border: 1px solid gray;
    border-radius: 7px;
}

.formulaVariableDefinition > input:first-of-type {
    width: 5%;
}

.formulaReferences > div {
    width: 100%;
    background: #bdbdbd;
    height: 15%;
    border-radius: 15px;
    margin-top: 15px;
}

.metricsContainer {
    display: flex;
    margin: 10px 20px;
    position: relative;
    justify-content: space-around;
}

.metric {
    background: #f1f1f1;
    border-radius: 10px;
    margin: 10px;
    height: 100px;
    width: 20%;
    border: 2px solid #c7c7c7;
    text-align: center;
    box-shadow: -3px 3px 3px #d2d2d2;
}

.metric h1 {
    font-size: 13px;
    font-weight: 400;
    color: #9c9c9c;
}

.metric span {
    font-size: 35px;
    font-weight: 700;
    color: #e05757;
}

.detailAnalyticsContainer .dashboardMetricsContainer {
    display: flex;
    margin: 0px;
    position: relative;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 98%;
    z-index: 99;
}

.detailAnalyticsContainer .dashboardMetricsContainer .metric {
    background: #f0f0f0;
    border-radius: 10px;
    margin: 10px;
    border: 2px solid #c7c7c7;
    text-align: center;
    box-shadow: -3px 3px 3px #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 19%;
    height: 85px;
    font-family: "montserrat";
    text-align: left;
    padding: 0px 20px;
}

.dashboardMetricsContainer {
    display: flex;
    margin: 10px 20px;
    position: relative;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 98%;
}

.dashboardMetricsContainer .metric {
    background: #f0f0f0;
    border-radius: 10px;
    margin: 10px;
    border: 2px solid #c7c7c7;
    text-align: center;
    box-shadow: -3px 3px 3px #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 18%;
    height: 85px;
    font-family: "montserrat";
    text-align: left;
}

.dashboardMetricsContainer .metric div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.dashboardMetricsContainer .metric h1 {
    font-size: 8px;
    font-weight: 400;
    color: black;
    margin: 0px 15px 0px 0px;
    width: 100px;
}

.dashboardMetricsContainer .metric i {
    font-size: 25px;
    color: #e08258;
    margin: 0px 10px;
}

.dashboardMetricsContainer .metric span {
    font-size: 18px;
    font-weight: 700;
    color: #e05757;
    margin: 0px;
}

.modalMetricsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    height: 327px;
    width: 25vw;
}

.modalMetricsContainer .metric {
    background: #f1f1f1;
    border-radius: 10px;
    margin: 3px 10px;
    border: 2px solid #c7c7c7;
    text-align: center;
    box-shadow: -3px 3px 3px #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 124px;
    font-family: "montserrat";
    text-align: left;
}

.modalMetricsContainer .metric div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.modalMetricsContainer .metric h1 {
    font-size: 8px;
    font-weight: 400;
    color: #9c9c9c;
    margin: 0px 15px 0px 0px;
    width: 100px;
}

.modalMetricsContainer .metric i {
    font-size: 25px;
    color: #e08258;
    margin: 0px 10px;
}

.modalMetricsContainer .metric span {
    font-size: 13px;
    font-weight: 700;
    color: #e05757;
    margin: 0px;
}

.overviewContent h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    margin: 0px 15px 0px 0px;
}

.overviewContent .selector {
    color: #b0b0b0;
    text-decoration: underline;
    font-weight: 100;
    font-size: 24px;
    font-family: "montserrat";
    cursor: pointer;
}

.overviewContent .selector:hover {
    color: #b3c0d7;
    font-weight: bold;
}

.overviewContent .selector.selected {
    color: #516483;
}

.overviewContent p {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
}

.overviewHeaderSelector {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: space-between;
}

.detailModal {
    position: fixed;
    height: 0px;
    opacity: 0;
    bottom: 0px;
    width: calc(100vw - 100px);
    left: 100px;
    z-index: 1000;
    background: #e2e2e2;
    border-top: 7px solid #cbbb00;
    transition: all 0.3s;
    transition-timing-function: ease-out;
}

.detailModalAnalytics {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 15px;
    height: 300px;
    width: 85vw;
    max-width: 90vw;
    min-width: 300px;
}

.detailInfo {
    display: flex;
    align-items: center;
    position: relative;
    top: 1px;
    background: #e2e2e2;
    border-bottom: 2px solid gray;
    height: 50px;
    width: 97%;
    padding: 0px 20px;
}

.detailInfo h1 {
    margin: 0px 15px;
    font-family: "montserrat";
    font-size: 20px;
}

.detailInfo p {
    font-family: "montserrat";
    font-size: 12px;
    padding-right: 10px;
    margin: 0px;
}

.detailTableParentContainer {
    overflow-y: scroll;
    height: 350px;
    width: 40%;
    margin-right: 5px;
}

.detailTableContainer {
    font-family: "montserrat";
    font-size: 12px;
    width: 100%;
}

.detailTableContainer td,
th {
    border: 1px solid #a9a9a9;
    text-align: left;
    padding: 8px;
}

.detailTableContainer td:first-of-type {
    text-align: center;
    width: 140px;
}

.detailTableContainer tr:nth-child(even) {
    background-color: #ececec;
}

.detailTableContainer h1 {
    font-family: "montserrat";
    font-size: 20px;
    font-weight: 500;
}

.detailTableContainer i {
    margin-right: 5px;
}

.detailTableContainer > div {
    display: flex;
}

.modalChart {
    position: relative;
    height: inherit;
    background: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 5px 3px 3px #d2d2d2;
    width: 100%;
}

.chartMenuItem {
    position: absolute;
}

.detailDataContainer {
    display: flex;
    /* width: 33%; */
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 350px;
}

.detailRulChart {
    position: absolute;
    width: 68%;
    left: 0px;
    top: 50px;
    height: 500px;
}

.detailScatterChart {
    position: absolute;
    width: 40%;
    right: 40px;
    top: 50px;
    height: 200px;
}

.detailLineChart {
    position: absolute;
    width: 40%;
    right: 40px;
    top: 250px;
    height: 155px;
}

.detailButtons {
    position: absolute;
    top: 12px;
    right: 43px;
}

.detailButtons button {
    width: 40px;
    height: 30px;
    font-size: 16px;
    margin-right: 15px;
}

.deviceLegacyContainer {
    position: absolute;
    left: 0px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.deviceDashboardContainer h1 {
    font-size: 20px;
    position: relative;
    left: 40px;
}

.deviceMiniContainer {
    position: relative;
    left: 0px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.deviceLegacyChart {
    width: 50%;
}

.inactiveNavItem {
    color: #888888;
    cursor: not-allowed !important;
}

.inactiveFilterItem {
    color: #888888 !important;
    cursor: not-allowed !important;

    input {
        color: #888888 !important;
        cursor: not-allowed !important;
    }

    span {
        color: #888888 !important;
        cursor: not-allowed !important;
    }
}

.breadcrumbs {
    display: flex;
    font-size: 12px;
    margin: 25px 0px 0px 15px;
    color: rgb(106, 106, 230);
}

.breadcrumbs:hover {
    color: rgb(48, 48, 188);
}

.manageDeviceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 12px;
        margin: 25px 0px 0px 15px;
    }

    span {
        display: flex;
        justify-content: flex-start;
        font-size: 12px;
        align-items: flex-end;
        margin: 0px 0px;

        > p {
            font-size: 12px;
            margin: 0px 15px 0px 0px;
        }
    }
}

.manageProjectHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manageProjectHeader p {
    margin: 25px 0px 0px 15px;
    font-size: 12px;
}

.manageDeviceButtons {
    margin-top: 25px;
    display: flex;

    .secondaryButton {
        color: #02113a;
        background: #e2e2e2;
        width: 40px;
    }
}

.formContainer {
    height: 73vh;
    width: 40%;
    overflow: scroll;
    padding: 15px;
    background: #fffdfa;
}

.logViewContainer {
    height: 450px;
    width: 97%;
    overflow-x: hidden;
    padding: 15px;
    background: #e9eef4;
    border-radius: 10px;
    margin-bottom: 20px;
}

.logViewContainer .viewTypeSelector {
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    display: flex;
    position: sticky;
    z-index: 1;
    justify-content: space-around;
    text-align: center;
    background: #f6faff;
    height: 30px;
    float: right;
    width: 140px;
    top: 0px;

    > div {
        display: flex;
        height: inherit;
        align-items: center;
        cursor: pointer;
    }

    > div.selected {
        background-color: #e9e9e9;
    }

    > div:nth-of-type(1) {
        border-radius: 10px 0px 0px 10px;
        border-right: 1px solid #cdcdcd;
    }

    > div:nth-of-type(2) {
        border-radius: 0px 10px 10px 0px;
    }

    > div:hover {
        background-color: #e9e9e9;
    }

    > div > h1 {
        font-size: 10px;
        background: none;
        color: gray;
        width: 60px;
        margin: 0px 5px;
        display: inline;
        text-align: center;
        height: 12px;
    }
}

.logViewContainer h1 {
    font-size: 13px;
    color: #999393;
    position: sticky;
    font-weight: 700;
    background: #e9eef5;
    width: 97%;
    height: 30px;
    top: -19px;
    display: flex;
    align-items: center;
}

.logViewContainer p {
    font-size: 12px;
    color: #656667;
    font-family: monospace;
    margin: 0px 0px 15px 0px;
}

.logViewContainer .reciept {
    color: #9a9027 !important;
}

.logViewContainer .conf {
    color: #41a356 !important;
}

.deviceLiveChart {
    width: 100%;
}

.configContainer {
    display: flex;
}

.configContainer .formContainer {
    height: 73vh;
    width: 40%;
    overflow: scroll;
    padding: 15px;
}

.configContainer .secondaryFormContainer {
    height: 73vh;
    width: 56%;
    padding: 15px 1px 15px 15px;
}

.authList {
    padding: 0px;
    position: relative;
    top: 40px;
}

/* .authList h1 {
	display: block;
	margin: 20px 0 0.28571429rem 0;
	color: rgba(0, 0, 0, .87);
	font-size: .92857143em;
	font-weight: 700;
	text-transform: none;
} */

.authList button {
    background: #f5f4f2;
    border: 0.5px solid #071534;
    border-radius: 5px;
    font-size: 13px;
    padding: 8px;
    cursor: pointer;
    font-family: montserrat;
    margin: 15px 0px 0px 0px;
    width: 100%;
    position: relative;
    bottom: -40px;
}

.authList button:hover {
    background: #dbdada;
}

.authList .defaultEmptyRow {
    width: 100%;
    background: #edf2fa;
    font-size: 13px;
    font-family: montserrat;
    height: 30px;
    display: flex;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
}

.authList h1 {
    font-size: 13px;
    color: #999393;
    font-weight: 700;
    font-family: "montserrat";
}

.inputContent {
    margin: 15px 0px !important;
    text-align: center;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    width: 100%;
}

.inputContent .listRow {
    font-size: 10px !important;
}

.inputContent .listRow > i {
    color: rgb(196, 196, 196);
    font-size: 12px !important;
    cursor: pointer;
    position: relative;
    left: 8px;
}

.inputContent .listRow i:hover {
    color: rgb(169, 35, 35);
}

.inputContent .listTableHeader p {
    width: 50% !important;
}

.inputContent .listTable {
    width: 100%;
}

.inputContent .listTableRows {
    overflow-x: scroll;
    max-height: 250px;
}

.inputContent .listTableRows p {
    width: 50% !important;
}

.remoteTriggers {
    margin: 15px 0px;
    background: whitesmoke;
    padding: 15px;
    border-radius: 10px;
}

.remoteTriggers h1 {
    font-size: 13px;
    color: #999393;
    font-weight: 700;
    font-family: "montserrat";
}

.remoteTriggers div {
    display: flex;
    width: 100%;
    overflow: scroll;
}

.remoteTriggers button {
    background: #e9eef4;
    border: 2px solid #e9eef4;
    border-radius: 10px;
    padding: 12px;
    font-size: 13px;
    font-family: montserrat;
    margin: 5px;
    width: 100%;
    cursor: pointer;
}

.remoteTriggers button:hover {
    background-color: #aaaaaa;
}

.transactionTable h1 {
    font-size: 13px;
    color: #999393;
    font-weight: 700;
    font-family: "montserrat";
}

.transactionTable .listTableHeader p {
    width: 20%;
    color: rgb(175, 175, 175);
    margin: 0px 15px;
    font-size: 12px;
}

.transactionTable .listTableHeader {
    display: inline-flex;
    width: 100%;
    font-family: "montserrat";
    font-size: 13px;
    height: 40px;
    border-bottom: 2px solid #afafaf;
    font-weight: 600;
    border-top: 2px solid #afafaf;
    align-items: center;
}

.transactionTable .listTableRows div:nth-child(even) {
    background: rgb(236 236 236);
}

.transactionTable .listRow {
    display: flex;
    font-family: "montserrat";
    align-items: center;
    cursor: pointer;
}

.transactionTable .listRow p {
    width: 20%;
    font-size: 11px;
    margin: 8px 15px;
    overflow: scroll;
    text-overflow: ellipsis;
    display: flex;
    white-space: nowrap;
}

.transactionTable .listTableRows {
    height: 300px;
    overflow: scroll;
}

.splitTableViewer {
    column-gap: 10px;
}

.splitTableViewer > div:first-of-type {
    display: flex;
}

.splitTableViewer > div:nth-of-type(2) {
    background: #fafafa;
    border-radius: 10px;
    padding: 15px;
    margin: 0px;
}

.splitTableViewer > div:nth-of-type(3) {
    background: #fafafa;
    border-radius: 10px;
    padding: 15px;
    margin: 0px;
}

.splitTableViewer h1 {
    font-size: 13px;
    color: #999393;
    font-weight: 700;
    font-family: "montserrat";
    margin: 10px 0px;
}

.splitTableViewer .inputContent {
    height: inherit;
}

.inputContent .listRow.selected {
    background: aliceblue;
    font-weight: 600;
    color: #7faac6;
}

.inputContent .listRow {
    height: 50px;
}

.inputContent .listTableRows {
    overflow-x: scroll;
    max-height: 400px;
    min-height: 50px;
}

.userMetricsView {
    height: inherit;
    margin-top: -10px;
    border-radius: 10px;
    margin-left: 10px;
    overflow: hidden;
}

.userMetricsView .detailTableContainer {
    font-family: "montserrat";
    font-size: 10px;
    width: 100%;
    color: #585d71;
}

.userMetricsView .detailParentTableContainer > h1 {
    margin: 10px 0px;
}

.userMetricsView .detailAnalyticsContainer {
    height: 70%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.userMetricsView .detailAnalyticsContainer > h1 {
    margin: 10px 0px;
}

.userMetricsView .detailTableContainer td:first-of-type {
    text-align: right;
    width: 140px;
    font-weight: 600;
}

.userMetricsView .detailTableContainer tr:nth-child(even) {
    background-color: #eeeeee;
}

.userManagement {
    font-family: "montserrat";
    font-size: 12px;
    width: 100%;
    color: #585d71;
    display: flex;
    flex-direction: column;
}

.userManagement > .detailUserForm{
    display: grid;
    grid-template-columns: auto auto;
    place-items: center;
    row-gap: 10px;
}


.card {
    border-radius: 10px;
    box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.15);
    display: flex;
    height: 213px;
    margin-bottom: 25px;
    max-width: 100%;
    padding: 10px;
}

.row {
    display: flex;
    flex-wrap: nowrap;
}

.settingsButton {
    background-color: #2140d9;
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 17px;
    height: 35px;
    width: 75px;
}

.settingsButton:hover,
.settingsButton:active {
    background: #1e3ca9;
    color: #ffee20;
}

.settingsButton[disabled] {
    background-color: #9b9b9b;
    cursor: not-allowed;
}

#divAutocompleteContainer {
    border: 1px solid rgb(128, 128, 128);
    border-radius: 10px;
    height: 33px;
    width: -webkit-fill-available;
    width: -moz-available;
}

.vehicleCard {
    flex-direction: column;

    i {
        font-size: 18px;
        color: #b1b1b1;
        cursor: pointer;
        padding: 3px 3px 0px 0px;
    }

    i:hover {
        color: #7a7a7a;
    }

    .vehicleNumber {
        border-radius: 4px;
        font-size: 11px;
        margin: 0px 5px 10px 5px;
        padding: 2px;
        border: 1px solid #d6d6d6;
        color: #939393;
        text-align: center;
    }

    > div {
        display: flex;
        flex-wrap: nowrap;
    }

    h4 {
        margin: 0px;
        padding-left: 6px;
        width: 90%;
    }

    img {
        height: auto;
        margin: auto;
        object-fit: cover;
        width: 90%;
    }

    .col {
        background-color: #fafafa;
        border-radius: 10px;
        margin-bottom: 5px;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        width: 70px;

        b {
            color: #212529;
            font-size: 14px;
        }

        p {
            font-size: 9px;
            margin: 2px;
        }
    }
}

.detailAnalyticsContainer {
    .dashboardMetricCharts {
        width: 100%;
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    .dashboardMetricCharts .analytic {
        background: #fbfbfb;
        border-radius: 20px;
        border-radius: 10px;
        margin: 0px -30px 10px 30px;
        text-align: center;
        height: 400px;
        border: 1px solid #f0f0f0;
        box-shadow: -3px 3px 3px #d2d2d2;
        padding: 20px;
    }
}

.userMetricCharts {
    width: 100%;
    display: flex;
    height: 100%;
}

.userMetricCharts .analytic {
    background: #ffffff;
    border-radius: 20px;
    border-radius: 10px;
    margin: 10px 10px 10px 0px;
    text-align: center;
    height: 400px;
    border: 1px solid #e0e0e0;
}

.userMetricCharts .chartContainer {
    height: 300px;
    width: 350px;
    position: relative;
    margin: auto;
}

.splitTableViewer form {
    float: none !important;
}

.userMetricCharts > .chartContainer {
    width: inherit;
}

.userMetricsView .metric {
    min-width: 50%;
}

.viewButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.viewButtons div {
    position: relative;
    width: 85%;

    input {
        height: 40px;
        border: 1px solid #c9c9c9;
        border-radius: 4px;
        padding: 10px;
        background: #ffffff;
        top: 0px;
        margin-left: 10px;
        width: 99%;
    }
}

.viewButtons .createInputButton {
    border: 1px solid silver;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 13px;
    padding: 0px 10px;
    color: black;
    height: 40px;
    cursor: pointer;
    width: 130px;
}

.viewButtons .createInputButton:hover {
    background-color: #dcdcdc;
}

.userMetricsView .dashboardMetricsContainer {
    display: flex;
    margin: 10px -8px;
    position: relative;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 98%;
}

.userMetricsView .dashboardMetricsContainer .metric h1 {
    font-size: 0.5em !important;
    font-weight: 400;
    color: black;
    margin: 0px 15px 0px 0px;
}

.userMetricsView .dashboardMetricsContainer .metric {
    background: #ffffff;
    border-radius: 10px;
    margin: 10px;
    border: 1px solid #e0e0e0;
    text-align: center;
    box-shadow: 0px 0px 0px;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 70px;
    font-family: "montserrat";
    text-align: left;
}

.dashboardMetricCharts > .layout {
    top: -60px;
}

.loaderParent {
    position: relative;
    height: 500px;
    width: 100%;
}

.buttonInputFieldsParent {
    height: 0%;
    opacity: 0;
    overflow: visible !important;
}

.buttonInputFields {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    background: whitesmoke;
    border-radius: 10px;
    overflow-x: visible !important;
    overflow-y: visible !important;

    .formInputs {
        display: flex;
        flex-direction: column;
        width: auto;
        overflow: visible;
        margin: 10px 5px;
        align-items: flex-start;

        label {
            font-size: 10px;
        }

        input {
            width: 142px;
            font-size: 10px;
        }
    }

    .createButtons {
        display: flex;
        /* align-items: center; */
        width: auto;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
        position: relative;
        top: 15px;
        overflow: visible;

        .primaryButton {
            background: #071534;
            width: 120px !important;
            height: 43px;
            font-size: 10px;
        }

        .secondaryButton {
            background: #6b6b6b;
            width: 80px !important;
            height: 43px;
            font-size: 10px;
        }
    }
}

.parentLoaderContainer {
    height: 50vh;
    width: inherit;
}

.modalLoader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: #e2e2e2;
    top: 0px;
}

.stationInvoiceContainer {
    align-items: flex-start;
    flex-direction: column;
    margin: 20px 0px;
    opacity: 1;

    > .invoiceSection {
        margin: 15px 0px 5px 0px;
        width: 100%;

        h1 {
            @media only screen and (max-width: 430px) {
                color: #121f62;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: bold;
            }

            @media only screen and (min-width: 431px) {
                color: #121f62;
                font-family: Montserrat;
                font-size: 13px;
                font-weight: bold;
            }
        }

        > .sectionDetails {
            @media only screen and (max-width: 430px) {
                font-size: 10px;
            }

            @media only screen and (min-width: 431px) {
                font-size: 12px;
            }

            color: #121f62;

            font-size: 12px;

            > div {
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                line-height: 0px;
            }

            p:first-child {
                text-align: left !important;
            }

            p:last-child {
                text-align: right;
            }

            .divider {
                background-image: linear-gradient(
                    to right,
                    #cecece 80%,
                    rgba(255, 255, 255, 0) 0%
                );
                background-position: bottom;
                background-repeat: repeat-x;
                background-size: 9px;
                flex: auto;
                height: 1px;
                margin: 0px 10px;
                padding: 0px 5px;
            }
        }
    }
}

.invoiceInfo {
    color: #828282;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: whitesmoke;
    border-radius: 10px;
    padding: 0 10px;

    p {
        width: 90%;
        font-size: 12px;
        margin-left: 10px;

        @media only screen and (max-width: 430px) {
            font-size: 10px;
        }
    }

    i {
        font-size: 18px;
    }

    span {
        color: #626262;
        font-weight: 600;
    }
}

.notifications {
    color: #5a5a5a;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #fde9e7;
    border-radius: 10px;
    padding: 8px 20px;
    width: 96%;
    margin: 3px auto;

    p {
        width: 100%;
        font-size: 12px;
        margin-left: 20px;
        font-family: "montserrat";
        font-weight: 800;
        color: #7a7c80;
    }

    i {
        color: #b90834;
        font-size: 15px;
    }

    .close {
        position: relative;
        top: -14px;
        font-size: 11px;
        left: 11px;
        cursor: pointer;
        color: #b6b6b6;
    }

    .close:hover {
        color: #848484;
    }

    span {
        color: #626262;
        font-weight: 600;
    }
}

.loadingCenter {
    margin: auto;
    height: 50%;
    padding: 10% 0 0 0;
}

.disabledButton {
    cursor: not-allowed;
}

@media screen and (max-width: 800px) {
    .slideView {
        width: 100%;
        /* The width is 100%, when the viewport is 800px or smaller */
    }

    .slideForm {
        width: 95%;
        left: 5%;
    }

    .landingHeader {
        margin: auto;
    }

    .publicLogo {
        float: none;
        text-align: center;
    }

    .publicNavMenu {
        display: flex;
        float: none;
        right: 0px;
        text-align: center;
        justify-content: space-evenly;
    }

    .publicNavMenu h1 {
        margin: 0 0 0 0;
    }

    .formInputs input {
        width: 91.5%;
    }

    .formInputs select {
        width: 100%;
    }

    .formButtons div {
        width: 96%;
        display: inline-flex;
        justify-content: center;
    }
}

#menu {
    background: #fff;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    border-radius: 3px;
    width: 120px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-family: "Open Sans", sans-serif;
}

#menu a {
    font-size: 13px;
    color: #404040;
    display: block;
    margin: 0;
    padding: 0;
    padding: 10px;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    text-align: center;
}

#menu a:last-child {
    border: none;
}

#menu a:hover {
    background-color: #f8f8f8;
    color: #404040;
}

#menu a.active {
    background-color: #3887be;
    color: #ffffff;
}

#menu a.active:hover {
    background: #3074a4;
}

.autocomplete-items {
    width: 101%;
	display: block !important;
    position: absolute;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    z-index: 999999;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
	max-height: 100px;
	overflow-y: scroll;
    scrollbar-color: blue red;
}
.autocomplete {
    position: relative;
    //display: inline-block;
	max-height: 150px;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-item-field {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
    font-size: small;
    display: flex;
    flex-direction: row;
}

/*when hovering an item:*/
.autocomplete-item-field:hover {
    background-color: #e9e9e9;
}

.autocomplete-item-field-icon {
    font-size: 18px;
    text-align: center;
    padding: 8px 10px 0px 0px;
}

.autocomplete-item-field-value {
    flex-direction: column;
}
.autocomplete-item-field-value-phoneNumber {
    font-weight: 600;
}
.autocomplete-item-field-value-username {
    font-size: smaller;
    color: grey;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
}


#hiddenIdTagAccount {
	visibility: hidden;
	height: 0px;
}

#hiddenIdTagAccount input {
	height: 0px;
    position: absolute;
}

#divIdTagInput {
    margin-top: 15px;
}

.autocomplete-items-remote {
    width: 101%;
	display: block !important;
    position: absolute;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    z-index: 999999;
    /*position the autocomplete items to be the same width as the container:*/
    top: 120%;
    left: 0;
    right: 0;
	max-height: 150px;
    overflow-x: hidden !important;
}

.autocomplete-remote {
    position: relative;
    //display: inline-block;
	max-height: 150px;
    height: fit-content;
    overflow: visible !important;
}
.autocomplete-item-background-remote {
    background-color: red;
}

.autocomplete-item-field-remote {
    font-size: x-small !important;
    padding: 6px 10px 0px 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
    font-size: small;
    display: flex !important;
    flex-direction: row;
}

/*when hovering an item:*/
.autocomplete-item-field-remote:hover {
    background-color: #e9e9e9;
}

.autocomplete-item-field-icon-remote {
    width: 32px !important;
    font-size: 18px;
    text-align: center;
    padding: 8px 2px 0px 0px;
}

.autocomplete-item-field-value-remote {
    flex-direction: column;
    padding-bottom: 0px;
}
.autocomplete-item-field-value-phoneNumber-remote {
    padding-top: 3px;
    font-size: 10px;
    font-weight: 600;
}
.autocomplete-item-field-value-username-remote {
    font-size: 9px;
    color: grey;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active-remote {
    background-color: DodgerBlue !important;
    color: #ffffff;
}